export const hypeQuotes = [
    { quote: "What is success? I think it is a mixture of having a flair for the thing that you are doing; knowing that it is not enough, that you have got to have hard work and a certain sense of purpose.", attribution: "Margaret Thatcher" },
    { quote: "The person without a purpose is like a ship without a rudder.", attribution: "Thomas Carlyle" },
    { quote: "When the will is ready the feet are light.", attribution: "Proverb" },
    { quote: "It is easier to act yourself into a new way of feeling rather than to feel yourself into a new way of acting.", attribution: "G. D. Morgan" },
    { quote: "Construct your determination with sustained effort, controlled attention, and concentrated energy. Opportunities never come to those who wait… they are captured by those who dare to attack.", attribution: "Paul J. Meyer" },
    { quote: "A vow is fixed and unalterable determination to do a thing, when such a determination is related to something noble which can only uplift the man who makes the resolve.", attribution: "Mahatma Gandhi" },
    { quote: "Obstacles are those frightful things you see when you take your eyes off your goal.", attribution: "Henry Ford" },
    { quote: "The man who goes farthest is generally the one who is willing to do and dare. The sure-thing boat never gets far from shore.", attribution: "Dale Carnegie" },
    { quote: "No one is wise by birth. Wisdom results from one’s own efforts.", attribution: "Krishnamacharya" },
    { quote: "Tomorrow is the only day in the year that appeals to a lazy man.", attribution: "Jimmy Lyon" },
    { quote: "Think little goals and expect little achievements. Think big goals and win big success.", attribution: "David J. Schwartz" },
    { quote: "There are no secrets to success. It is the result of preparation, hard work, and learning from failure.", attribution: "Colin Powell" },
    { quote: "Nothing really worth having comes quickly and easily. If it did, I doubt that we would ever grow.", attribution: "Eknath Easwaran" },
    { quote: "Problems are only opportunities in work clothes.", attribution: "Henry J. Kaiser" },
    { quote: "Wishes are like seeds – few ever develop into something.", attribution: "Michael Garofalo" },
    { quote: "Nothing can withstand the power of the human will if it is willing to stake its very existence to the extent of its purpose.", attribution: "Benjamin Disraeli" },
    { quote: "What may be done at any time will be done at no time.", attribution: "Scottish Proverb" },
    { quote: "If you have made mistakes, even serious ones, there is always another chance for you. What we call failure is not the falling down, but the staying down.", attribution: "Mary Pickford" },
    { quote: "There is not great talent without great will power.", attribution: "Honoré de Balzac" },
    { quote: "Put your heart, mind, intellect and soul even to your smallest acts. This is the secret of success.", attribution: "Swami Sivananda" },
    { quote: "What you have to do and the way you have to do it is incredibly simple. Whether you are willing to do it, that’s another matter.", attribution: "Peter F. Drucker" },
    { quote: "Willingness is essential in any initiation or in making any dream come true. 'I can’t' often means 'I won’t.' You can change 'I won’t' to 'I will' with will power.", attribution: "Marcia Wieder" },
    { quote: "As long as I have you there is just one other thing I’ll always need — tremendous self-control.", attribution: "Ashleigh Brilliant" },
    { quote: "People do not lack strength, they lack will.", attribution: "Victor Hugo" },
    { quote: "Gongfu is an ancient Chinese term describing work/devotion/effort that has been successfully applied over a substantial period of time, resulting in a degree of mastery in a specific field. Although the term is synonymous in the West with martial arts (often rendered 'Kung Fu'), it is equally applicable to calligraphy, painting, music, or other areas of endeavor.", attribution: "Andy James" },
    { quote: "Never give up on what you really want to do. The person with big dreams is more powerful than one with all the facts.", attribution: "Life’s Little Instruction Calendar" },
    { quote: "The world makes way for the man who knows where he is going.", attribution: "Ralph Waldo Emerson" },
    { quote: "The best day of your life is the one on which you decide your life is your own. No apologies or excuses. No one to lean on, rely on, or blame. The gift is yours – it is an amazing journey – and you alone are responsible for the quality of it. This is the day your life really begins.", attribution: "Bob Moawad" },
    { quote: "The obstacles that distract thought are disease, apathy, doubt, carelessness, indolence, dissipation, false vision, failure to attain a firm basis in yoga, and restlessness.", attribution: "Patanjali" },
    { quote: "How like herrings and onions our vices are in the morning after we have committed them.", attribution: "Samuel Taylor Coleridge" },
    { quote: "Desire is the key to motivation, but it’s the determination and commitment to an unrelenting pursuit of your goal — a commitment to excellence — that will enable you to attain the success you seek.", attribution: "Mario Andretti" },
    { quote: "You miss 100% of the shots you don’t take.", attribution: "Wayne Gretzky" },
    { quote: "Self-will so ardent and active that it will break a world to pieces to make a stool to sit on.", attribution: "Richard Cecil" },
    { quote: "Willpower is the key to success. Successful people strive no matter what they feel by applying their will to overcome apathy, doubt or fear.", attribution: "Dan Millman" },
    { quote: "Tomorrow is the day when idlers work, and fools reform.", attribution: "Edward Young" },
    { quote: "What it boils down to is this – it’s up to you to get hold of your mind, control it, quiet it. God does the rest. And immediately, as Eckhart says, 'The being ready and the pouring in are all one act.'", attribution: "Nancy Pope de Mayorga" },
    { quote: "Will is character in action.", attribution: "William McDougall" },
    { quote: "The future belongs to those who believe in the beauty of their dreams.", attribution: "Eleanor Roosevelt" },
    { quote: "We all have dreams. But in order to make dreams come into reality, it takes an awful lot of determination, dedication, self-discipline, and effort.", attribution: "Jesse Owens" },
    { quote: "The most important thing about having goals is having one.", attribution: "Geoffrey F. Abert" },
    { quote: "Great souls have wills; feeble ones have only wishes.", attribution: "Chinese Proverb" },
    { quote: "A failure establishes only this, that our determination to succeed was not strong enough.", attribution: "John C. Bovee" },
    { quote: "Will power is to the mind like a strong blind man who carries on his shoulders a lame man who can see.", attribution: "Arthur Schopenhauer" },
    { quote: "They must change often, who would be constant in happiness.", attribution: "Confucius" },
    { quote: "If your determination is fixed, I do not counsel you to despair. Few things are impossible to diligence and skill. Great works are performed not by strength, but perseverance.", attribution: "Samuel Johnson" },
    { quote: "I long to accomplish great and noble tasks, but it is my chief duty to accomplish humble tasks as though they were great and noble. The world is moved along not only by the mighty shoves of its heroes, but also by the aggregate of the tiny pushes of each honest worker.", attribution: "Helen Keller" },
    { quote: "Nothing is so fatiguing as the eternal hanging on of an uncompleted task.", attribution: "William James" },
    { quote: "Willpower is the art of replacing one habit for another.", attribution: "Michael Garofalo" },
    { quote: "The greatest waste in the world is the difference between what we are and what we could become.", attribution: "Ben Herbster" },
    { quote: "You may be disappointed if you fail, but you are doomed if you don’t try.", attribution: "Beverly Sills" },
    { quote: "A determined soul will do more with a rusty monkey wrench than a loafer will accomplish with all the tools in a machine shop.", attribution: "Robert Hughes" },
    { quote: "The only good luck many great men ever had was being born with the ability and determination to overcome bad luck.", attribution: "Channing Pollock" },
    { quote: "Men who are resolved to find a way for themselves will always find opportunities enough; and if they do not find them, they will make them.", attribution: "Samuel Smiles" },
    { quote: "Success is simply a matter of luck. Ask any failure.", attribution: "Earl Wilson" },
    { quote: "Awareness by itself is not enough: it must be joined by mastery. We need gradually to develop a steering ability to keep ourselves from slipping mechanically into this or that sub-personality. Thus we become able to identify with each part of our being as we wish. We can have more choice. It is the difference between being impotently transported by a roller coaster and, instead, driving a car and being able to choose which way to go and for what purpose to make the journey.", attribution: "Piero Ferrucci" },
    { quote: "If one does not know to which port he is sailing, no wind is favorable.", attribution: "Seneca" },
    { quote: "We are a product of the choices we make, not the circumstances that we face.", attribution: "Roger Crawford" },
    { quote: "A will finds a way.", attribution: "Orison Swett Marden" },
    { quote: "Determination that just won’t quit — that’s what it takes.", attribution: "A. J. Foyt" },
    { quote: "The most essential factor is persistence — the determination never to allow your energy or enthusiasm to be dampened by the discouragement that must inevitably come.", attribution: "James Whitcomb Riley" },
    { quote: "Let’s ask God to help us to self-control, for one who lacks it, lacks His grace.", attribution: "Mevlana Rumi" },
    { quote: "Let us not be content to wait and see what will happen, but give us the determination to make the right things happen.", attribution: "Peter Marshall" },
    { quote: "What really distinguishes this generation in all countries from earlier generations… is its determination to act, its joy in action, the assurance of being able to change things by one’s own efforts.", attribution: "Hannah Arendt" },
    { quote: "Procrastination is the thief of time.", attribution: "Edward Young" },
    { quote: "If you set goals and go after them with all the determination you can muster, your gifts will take you places that will amaze you.", attribution: "Les Brown" },
    { quote: "Mind is all that counts.", attribution: "Robert Collier" },
    { quote: "Goals are discovered, not made.", attribution: "Richard J. Foster" },
    { quote: "When you blame others, you give up your power to change.", attribution: "Douglas Noel Adams" },
    { quote: "I honestly think it is better to be a failure at something you love than to be a success at something you hate.", attribution: "George Burns" },
    { quote: "You are successful the moment you start moving toward a worthwhile goal.", attribution: "Charles Carlson" },
    { quote: "The secret of happiness lies in taking a genuine interest in all the details of daily life, and in elevating them to art.", attribution: "William Morris" },
    { quote: "Never give up, for this is just the place and time that the tide will turn.", attribution: "Harriet Beecher Stowe" },
    { quote: "A major part of successful living lies in the ability to put first things first. Indeed the reason most major goals are not achieved is that we spend our time doing second things first.", attribution: "Robert J. McKain" },
    { quote: "Continuous, unflagging effort, persistence and determination will win. Let not the man be discouraged who has these.", attribution: "James Whitcomb Riley" },
    { quote: "Do not be too timid and squeamish about your actions. All life is an experiment. The more experiments you make, the better. What if they are a little coarse and you get your coat soiled or torn? What if you do fail and get rolled in the dirt once or twice? Up again – you shall never be so afraid of a tumble.", attribution: "Ralph Waldo Emerson" },
    { quote: "The greater the obstacle, the more glory in overcoming it.", attribution: "Molière" },
    { quote: "Don’t worry about genius. Don’t worry about being clever. Trust to hard work, perseverance and determination. The best motto for a long march is: 'Don’t grumble. Plug on!'", attribution: "Sir Thomas Treves" },
    { quote: "Progress in every age results only from the fact that there are some men and women who refuse to believe that what they know to be right cannot be done.", attribution: "Russell W. Davenport" },
    { quote: "Any significant long-term change requires long-term practice, whether that change has to do with playing the violin or learning to be a more open, loving person. We all know people who say they have been permanently changed by experiences of a moment or a day or a weekend. But when you check it out you’ll generally discover that those who ended up permanently changed had spent considerable time preparing for their life-changing experience or had continued diligently practicing the new behavior afterward.", attribution: "Michael Murphy and George Leonard" },
    { quote: "We may think there is willpower involved, but more likely change is due to want power. Wanting the new addiction more than the old one. Wanting the new me in preference to the person I am now.", attribution: "George Sheehan" },
    { quote: "Give me a stock clerk with a goal and I’ll give you a man who will make history. Give me a man with no goals and I’ll give you a stock clerk.", attribution: "J. C. Penney" },
    { quote: "It seems to me that perfection of means and confusion of goals seem to characterize our age.", attribution: "Ralph Waldo Emerson" },
    { quote: "Heaven is blessed with perfect rest but the blessing of earth is toil.", attribution: "Henry van Dyke" },
    { quote: "Effort only fully releases its reward after a person refuses to quit.", attribution: "Napoleon Hill" },
    { quote: "Life is like a game of cards. The hand that is dealt you represents determinism; the way you play it is your own will.", attribution: "Jawaharlal Nehru" },
    { quote: "Build your life brick upon brick, live a life of truth, and you will look back on a life of truth. Live a life of fantasy, and you will look back on delusion.", attribution: "Deng Ming-Dao" },
    { quote: "The world is moving so fast these days that the man who says it can’t be done is generally interrupted by someone doing it.", attribution: "Elbert Hubbard" },
    { quote: "Good character is more to be praised than outstanding talent. Most talents are, to some extent, a gift. Good character, by contrast, is not given to us. We have to build it piece by piece – by thought, choice, courage and determination.", attribution: "John Luther" },
    { quote: "Good thoughts are no better than good dreams, unless they are executed.", attribution: "Ralph Waldo Emerson" },
    { quote: "Achieving goals by themselves will never make us happy in the long term; it’s who we become, as we overcome the obstacles necessary to achieve our goals, that can give us the deepest and most long-lasting sense of fulfillment.", attribution: "Tony Robbins" },
    { quote: "In truth, people can generally make time for what they choose to do; it’s not really the time but the will that is lacking.", attribution: "John Lubbock" },
    { quote: "Without continual growth and progress, such words as improvement, achievement, and success have no meaning.", attribution: "Benjamin Franklin" },
    { quote: "Setbacks add some salt to every sweet success.", attribution: "Michael Garofalo" },
    { quote: "If you want to reach a goal, you must ‘see the reaching’ in your own mind before you actually arrive at your goal.", attribution: "Zig Ziglar" },
    { quote: "Change is the law of life. And those who look only to the past or present are certain to miss the future.", attribution: "John F. Kennedy" },
    { quote: "Snowflakes are one of nature’s most fragile things, but just look at what they can do when they stick together.", attribution: "Vesta Kelly" },
    { quote: "Failure will never overtake me if my determination to succeed is strong enough.", attribution: "Og Mandino" },
    { quote: "Positive anything is better than negative nothing.", attribution: "Elbert Hubbard" },
    { quote: "One supreme fact which I have discovered is that it is not willpower, but fantasy and imagination that creates. Imagination is the creative force. Imagination creates reality.", attribution: "Richard Wagner" },
    { quote: "You always pass failure on the way to success.", attribution: "Mickey Rooney" },
    { quote: "Prudent, cautious self-control is wisdom’s root.", attribution: "Robert Burns" },
    { quote: "Imagination is the beginning of creation. You imagine what you desire, you will what you imagine, and at last you create what you will.", attribution: "George Bernard Shaw" },
    { quote: "Rather fail with honor than succeed by fraud.", attribution: "Sophocles" },
    { quote: "Attention is the beginning of devotion.", attribution: "Mary Oliver" },
    { quote: "The first step toward success is taken when you refuse to be a captive of the environment in which you first find yourself.", attribution: "Mark Caine" },
    { quote: "In matters of style, swim with the current; in matters of principle, stand like a rock.", attribution: "Thomas Jefferson" },
    { quote: "Choose a job you love, and you will never have to work a day in your life.", attribution: "Confucius" },
    { quote: "Energy and persistence conquer all things.", attribution: "Benjamin Franklin" },
    { quote: "Nothing splendid has ever been achieved except by those who dared to believe that something inside them was superior to circumstance.", attribution: "Bruce Barton" },
    { quote: "What it boils down to is this: it’s up to you to get hold of your mind, control it, quiet it. God does the rest.", attribution: "Peter F. Drucker" },
    { quote: "Winners are not afraid of losing. But losers are. Failure is part of the process of success.", attribution: "Robert T. Kiyosaki" },
    { quote: "Success is not final, failure is not fatal: It is the courage to continue that counts.", attribution: "Winston Churchill" },
    { quote: "The harder you work for something, the greater you’ll feel when you achieve it.", attribution: "Anonymous" },
    { quote: "Never underestimate the power of determination.", attribution: "Anonymous" },
    { quote: "Push yourself because no one else is going to do it for you.", attribution: "Anonymous" },
    { quote: "Your potential is endless. Go do what you were created to do.", attribution: "Anonymous" },
    { quote: "Rise above the storm and you will find the sunshine.", attribution: "Mario Fernandez" }
  ];
  
  export const zenQuotes = [
    { quote: "I have so much to do that I shall spend the first three hours in prayer.", attribution: "Martin Luther" },
    { quote: "You’ll be calm the day you learn to sit alone and do nothing.", attribution: "Maxime Lagacé" },
    { quote: "The most beautiful experience we can have is the mysterious – the fundamental emotion which stands at the cradle of true art and true science.", attribution: "Albert Einstein" },
    { quote: "The personal life deeply lived always expands into truths beyond itself.", attribution: "Anaïs Nin" },
    { quote: "When you arise in the morning think of what a privilege it is to be alive, to think, to enjoy, to love.", attribution: "Marcus Aurelius" },
    { quote: "The next message you need is always right where you are.", attribution: "Ram Dass" },
    { quote: "Awakening is not changing who you are, but discarding who you are not.", attribution: "Deepak Chopra" },
    { quote: "Only if we understand, will we care. Only if we care, will we help. Only if we help shall all be saved.", attribution: "Jane Goodall" },
    { quote: "I close my eyes in order to see.", attribution: "Paul Gauguin" },
    { quote: "Don’t try to steer the river.", attribution: "Deepak Chopra" },
    { quote: "At any moment, you have a choice that either leads you closer to your spirit or further away from it.", attribution: "Thich Nhat Hanh" },
    { quote: "You have to grow from the inside out. None can teach you, none can make you spiritual. There is no other teacher but your own soul.", attribution: "Swami Vivekananda" },
    { quote: "The least of things with a meaning is worth more in life than the greatest of things without it.", attribution: "Carl Jung" },
    { quote: "Spirituality: the art of keeping your internal fire alive.", attribution: "Maxime Lagacé" },
    { quote: "Let the breath lead the way.", attribution: "Sharon Salzberg" },
    { quote: "The spiritual journey is the unlearning of fear and the acceptance of love.", attribution: "Marianne Williamson" },
    { quote: "Within you there is a stillness and a sanctuary to which you can retreat at any time and be yourself.", attribution: "Hermann Hesse" },
    { quote: "The spiritual life does not remove us from the world but leads us deeper into it.", attribution: "Henri J.M. Nouwen" },
    { quote: "Religion, science, and spirituality help us make sense of the world. Life without at least one of them is a lonely and confusing place.", attribution: "Naval Ravikant" },
    { quote: "Realize deeply that the present moment is all you have. Make the now the primary focus of your life.", attribution: "Eckhart Tolle" },
    { quote: "Being at ease with not knowing is crucial for answers to come to you.", attribution: "Eckhart Tolle" },
    { quote: "Enlightenment means taking full responsibility for your life.", attribution: "William Blake" },
    { quote: "He who is filled with love is filled with God himself.", attribution: "Augustine of Hippo" },
    { quote: "When you connect to the silence within you, that is when you can make sense of the disturbance going on around you.", attribution: "Stephen Richards" },
    { quote: "Fools laugh at others. Wisdom laughs at itself.", attribution: "Osho" },
    { quote: "It is in our wild nature that we best recover from our un-nature, our spirituality.", attribution: "Friedrich Nietzsche" },
    { quote: "A single event can awaken within us a stranger totally unknown to us. To live is to be slowly born.", attribution: "Antoine de Saint-Exupéry" },
    { quote: "This moment is all there is.", attribution: "Rumi" },
    { quote: "If you think you’re enlightened, go spend a week with your family.", attribution: "Ram Dass" },
    { quote: "The moment one gives close attention to anything, even a blade of grass, it becomes a mysterious, awesome, indescribably magnificent world in itself.", attribution: "Henry Miller" },
    { quote: "God will not look you over for medals, degrees or diplomas but for scars.", attribution: "Elbert Hubbard" },
    { quote: "I choose gentleness… Nothing is won by force. I choose to be gentle. If I raise my voice, may it be only in praise. If I clench my fist, may it be only in prayer. If I make a demand, may it be only of myself.", attribution: "Max Lucado" },
    { quote: "Learn to get in touch with the silence within yourself and know that everything in this life has a purpose. There are no mistakes, no coincidences. All events are blessings given to us to learn from.", attribution: "Elisabeth Kübler-Ross" },
    { quote: "When you realize there is nothing lacking, the whole world belongs to you.", attribution: "Lao Tzu" },
    { quote: "The soul is placed in the body like a rough diamond and must be polished, or its luster will never appear.", attribution: "Daniel Defoe" },
    { quote: "Realization is not acquisition of anything new; nor is it a new faculty. It is only the removal of all camouflage.", attribution: "Ramana Maharshi" },
    { quote: "If a man is to live, he must be all alive: body, soul, mind, heart, spirit.", attribution: "Thomas Merton" },
    { quote: "Relax into the moment and let the universe do the driving.", attribution: "Jed McKenna" },
    { quote: "Nurture great thoughts, for you will never go higher than your thoughts.", attribution: "Benjamin Disraeli" },
    { quote: "Who looks outside, dreams. Who looks inside, awakens.", attribution: "Carl Jung" },
    { quote: "The unexamined life is not worth living.", attribution: "Socrates" },
    { quote: "Just as a candle cannot burn without fire, men cannot live without a spiritual life.", attribution: "Buddha" },
    { quote: "We can never obtain peace in the outer world until we make peace with ourselves.", attribution: "Dalai Lama" },
    { quote: "It isn’t until you come to a spiritual understanding of who you are – not just a religious feeling, but deep down, the spirit within – that you can begin to take control.", attribution: "Oprah Winfrey" },
    { quote: "You must live in the present, launch yourself on every wave, find your eternity in each moment. Fools stand on their island of opportunities and look toward another land. There is no other land; there is no other life but this.", attribution: "Henry David Thoreau" },
    { quote: "Nothing is, unless our thinking makes it so.", attribution: "William Shakespeare" },
    { quote: "The Way is not in the sky; the Way is in the heart.", attribution: "Buddha" },
    { quote: "It is better in prayer to have a heart without words than words without a heart.", attribution: "Mahatma Gandhi" },
    { quote: "Physical strength can never permanently withstand the impact of spiritual force.", attribution: "Franklin D. Roosevelt" },
    { quote: "It is not in the stars to hold our destiny but in ourselves.", attribution: "William Shakespeare" },
    { quote: "This country cannot afford to be materially rich and spiritually poor.", attribution: "John F. Kennedy" },
    { quote: "This new day is too dear, with its hopes and invitations, to waste a moment on the yesterdays.", attribution: "Ralph Waldo Emerson" },
    { quote: "Meditation is the dissolution of thoughts in eternal awareness or pure consciousness without objectification – knowing without thinking, merging finitude in infinity.", attribution: "Voltaire" },
    { quote: "My religion consists of a humble admiration of the illimitable superior spirit who reveals Himself in the slight details we are able to perceive with our frail and feeble mind.", attribution: "Albert Einstein" },
    { quote: "The greater the doubt, the greater the awakening.", attribution: "Albert Einstein" },
    { quote: "Out beyond ideas of wrongdoing and rightdoing, there is a field. I’ll meet you there.", attribution: "Rumi" },
    { quote: "Be here now.", attribution: "Ram Dass" },
    { quote: "Yesterday I was clever, so I wanted to change the world. Today I am wise, so I am changing myself.", attribution: "Rumi" },
    { quote: "The only Zen you can find on the tops of mountains is the Zen you bring up there.", attribution: "Robert M. Pirsig" },
    { quote: "When you realize how perfect everything is, you will tilt your head back and laugh at the sky.", attribution: "Buddha" },
    { quote: "Empty your mind, be formless, shapeless — like water. You put water into a cup, it becomes the cup. You put water into a bottle, it becomes the bottle. You put it in a teapot, it becomes the teapot. Now water can flow or it can crash. Be water, my friend.", attribution: "Bruce Lee" },
    { quote: "Peace comes from within. Do not seek it without.", attribution: "Buddha" },
    { quote: "Knowing others is intelligence; knowing yourself is true wisdom. Mastering others is strength; mastering yourself is true power.", attribution: "Lao Tzu" },
    { quote: "The only journey is the one within.", attribution: "Rainer Maria Rilke" },
    { quote: "Your sacred space is where you can find yourself over and over again.", attribution: "Joseph Campbell" },
    { quote: "The privilege of a lifetime is to become who you truly are.", attribution: "Carl Jung" },
    { quote: "You must be the change you wish to see in the world.", attribution: "Mahatma Gandhi" },
    { quote: "What you seek is seeking you.", attribution: "Rumi" },
    { quote: "When the student is ready, the teacher will appear.", attribution: "Zen Proverb" },
    { quote: "Life is not a problem to be solved, but a reality to be experienced.", attribution: "Søren Kierkegaard" },
    { quote: "When one loses the deep intimate relationship with nature, then temples, mosques and churches become important.", attribution: "Jiddu Krishnamurti" },
    { quote: "The invariable mark of wisdom is to see the miraculous in the common.", attribution: "Ralph Waldo Emerson" },
    { quote: "We are not human beings having a spiritual experience. We are spiritual beings having a human experience.", attribution: "Pierre Teilhard de Chardin" },
    { quote: "The kingdom of God is within you.", attribution: "Jesus" },
    { quote: "The soul would have no rainbow if the eyes had no tears.", attribution: "Native American Proverb" },
    { quote: "The meaning of life is just to be alive. It is so plain and so obvious, and yet everybody rushes around in a great panic as if it were necessary to achieve something beyond themselves.", attribution: "Alan Watts" },
    { quote: "Man suffers only because he takes seriously what the gods made for fun.", attribution: "Alan Watts" },
    { quote: "There are only two ways to live your life. One is as though nothing is a miracle. The other is as though everything is a miracle.", attribution: "Albert Einstein" },
    { quote: "To understand everything is to forgive everything.", attribution: "Buddha" },
    { quote: "Our greatest glory is not in never falling, but in rising every time we fall.", attribution: "Confucius" },
    { quote: "No rain, no flowers.", attribution: "Zen Proverb" },
    { quote: "The world is a mountain, in which your words are echoed back to you.", attribution: "Rumi" },
    { quote: "Heaven is not a place out there; it is the inner silence of the soul.", attribution: "Ramana Maharshi" },
    { quote: "One does not become enlightened by imagining figures of light, but by making the darkness conscious.", attribution: "Carl Jung" },
    { quote: "Being spiritual has nothing to do with what you believe and everything to do with your state of consciousness.", attribution: "Eckhart Tolle" },
    { quote: "Those who know do not speak. Those who speak do not know.", attribution: "Lao Tzu" },
    { quote: "Nature does not hurry, yet everything is accomplished.", attribution: "Lao Tzu" },
    { quote: "When I let go of what I am, I become what I might be.", attribution: "Lao Tzu" },
    { quote: "The flame that burns twice as bright burns half as long.", attribution: "Lao Tzu" },
    { quote: "Mastering others is strength; mastering yourself is true power.", attribution: "Lao Tzu" },
    { quote: "At the center of your being you have the answer; you know who you are and you know what you want.", attribution: "Lao Tzu" },
    { quote: "Flow with whatever is happening and let your mind be free. Stay centered by accepting whatever you are doing. This is the ultimate.", attribution: "Zhuangzi" },
    { quote: "Happiness is the absence of the striving for happiness.", attribution: "Zhuangzi" },
    { quote: "To the mind that is still, the whole universe surrenders.", attribution: "Lao Tzu" },
    { quote: "Before enlightenment, chop wood, carry water. After enlightenment, chop wood, carry water.", attribution: "Zen Proverb" },
    { quote: "Sitting quietly, doing nothing, spring comes, and the grass grows by itself.", attribution: "Zen Proverb" },
    { quote: "When walking, walk. When eating, eat.", attribution: "Zen Proverb" },
    { quote: "To study the self is to forget the self. To forget the self is to be enlightened by all things.", attribution: "Dogen" },
    { quote: "If you cannot find the truth right where you are, where else do you expect to find it?", attribution: "Dogen" },
    { quote: "In the beginner’s mind there are many possibilities, but in the expert’s mind there are few.", attribution: "Shunryu Suzuki" },
    { quote: "Enlightenment is intimacy with all things.", attribution: "Dogen" },
    { quote: "Muddy water is best cleared by leaving it alone.", attribution: "Alan Watts" },
    { quote: "The quieter you become, the more you are able to hear.", attribution: "Rumi" },
    { quote: "Silence is the language of God, all else is poor translation.", attribution: "Rumi" },
    { quote: "You are not a drop in the ocean. You are the entire ocean in a drop.", attribution: "Rumi" },
    { quote: "My religion is very simple. My religion is kindness.", attribution: "Dalai Lama" },
    { quote: "Feelings come and go like clouds in a windy sky. Conscious breathing is my anchor.", attribution: "Thich Nhat Hanh" },
    { quote: "The best way to take care of the future is to take care of the present moment.", attribution: "Thich Nhat Hanh" },
    { quote: "Smile, breathe, and go slowly.", attribution: "Thich Nhat Hanh" },
    { quote: "Simplicity, patience, compassion. These three are your greatest treasures.", attribution: "Lao Tzu" },
    { quote: "If you do not change direction, you may end up where you are heading.", attribution: "Lao Tzu" },
    { quote: "Silence is a true friend who never betrays.", attribution: "Confucius" },
    { quote: "Truth is not something outside to be discovered; it is something inside to be realized.", attribution: "Osho" },
    { quote: "Be — don’t try to become.", attribution: "Osho" },
    { quote: "Your own Self-Realization is the greatest service you can render the world.", attribution: "Ramana Maharshi" },
    { quote: "Wherever you are is the entry point.", attribution: "Kabir" },
    { quote: "We are what we think. All that we are arises with our thoughts. With our thoughts, we make the world.", attribution: "Buddha" },
    { quote: "There is no fear for one whose mind is not filled with desires.", attribution: "Buddha" },
    { quote: "To live a pure unselfish life, one must count nothing as one’s own in the midst of abundance.", attribution: "Buddha" },
    { quote: "If anything is worth doing, do it with all your heart.", attribution: "Buddha" },
    { quote: "Do not look for a sanctuary in anyone except yourself.", attribution: "Buddha" },
    { quote: "Work out your own salvation. Do not depend on others.", attribution: "Buddha" },
    { quote: "No one saves us but ourselves. No one can and no one may. We ourselves must walk the path.", attribution: "Buddha" },
    { quote: "A man is not called wise because he talks and talks again; but if he is peaceful, loving and fearless then he is in truth called wise.", attribution: "Buddha" },
    { quote: "If we fail to look after others when they need help, who will look after us?", attribution: "Buddha" },
    { quote: "One who acts on truth is happy in this world and beyond.", attribution: "Buddha" },
    { quote: "The root of suffering is attachment.", attribution: "Buddha" },
    { quote: "The greatest gift is to give people your enlightenment – to share it. It has to be the greatest.", attribution: "Buddha" },
    { quote: "Even death is not to be feared by one who has lived wisely.", attribution: "Buddha" },
    { quote: "Silence the angry man with love. Silence the ill-natured man with kindness. Silence the miser with generosity. Silence the liar with truth.", attribution: "Buddha" },
    { quote: "Irrigators channel waters; fletchers straighten arrows; carpenters bend wood; the wise master themselves.", attribution: "Buddha" },
    { quote: "Even as a solid rock is unshaken by the wind, so are the wise unshaken by praise or blame.", attribution: "Buddha" },
    { quote: "If you find no one to support you on the spiritual path, walk alone.", attribution: "Buddha" },
    { quote: "You yourself must strive. The Buddhas only point the way.", attribution: "Buddha" },
    { quote: "Nothing can harm you as much as your own unguarded thoughts.", attribution: "Buddha" },
    { quote: "Meditate… do not delay, lest you later regret it.", attribution: "Buddha" },
    { quote: "Better than a thousand hollow words is one word that brings peace.", attribution: "Buddha" },
    { quote: "Delight in meditation and solitude. Compose yourself, be happy. You are a seeker.", attribution: "Buddha" },
    { quote: "Ardently do today what must be done. Who knows? Tomorrow, death comes.", attribution: "Buddha" },
    { quote: "You, yourself, must strive. The Buddhas only point the way.", attribution: "Buddha" },
    { quote: "If you know what love is, it is because of you.", attribution: "Buddha" },
    { quote: "If you propose to speak, always ask yourself, is it true, is it necessary, is it kind.", attribution: "Buddha" },
    { quote: "Stop, stop. Do not speak. The ultimate truth is not even to think.", attribution: "Buddha" },
    { quote: "Endurance is one of the most difficult disciplines, but it is to the one who endures that the final victory comes.", attribution: "Buddha" },
    { quote: "Ambition is like love, impatient both of delays and rivals.", attribution: "Buddha" },
    { quote: "Wear your ego like a loose fitting garment.", attribution: "Buddha" },
    { quote: "Attachment leads to suffering.", attribution: "Buddha" },
    { quote: "May all beings have happy minds.", attribution: "Buddha" },
    { quote: "What you are is what you have been. What you’ll be is what you do now.", attribution: "Buddha" },
    { quote: "Give, even if you only have a little.", attribution: "Buddha" },
    { quote: "Every human being is the author of his own health or disease.", attribution: "Buddha" },
    { quote: "The tongue like a sharp knife… Kills without drawing blood.", attribution: "Buddha" },
    { quote: "A jug fills drop by drop.", attribution: "Buddha" },
    { quote: "Live every act fully, as if it were your last.", attribution: "Buddha" },
    { quote: "One moment can change a day, one day can change a life, and one life can change the world.", attribution: "Buddha" },
    { quote: "She who knows life flows, feels no wear or tear, needs no mending or repair.", attribution: "Buddha" },
    { quote: "Purity or impurity depends on oneself. No one can purify another.", attribution: "Buddha" },
    { quote: "To be idle is a short road to death and to be diligent is a way of life; foolish people are idle, wise people are diligent.", attribution: "Buddha" },
    { quote: "If we could see the miracle of a single flower clearly, our whole life would change.", attribution: "Buddha" },
    { quote: "True love is born from understanding.", attribution: "Buddha" },
    { quote: "Hatred does not cease through hatred at any time. Hatred ceases through love. This is an unalterable law.", attribution: "Buddha" },
    { quote: "Kindness should become the natural way of life, not the exception.", attribution: "Buddha" },
    { quote: "Speak only endearing speech, speech that is welcomed. Speech, when it brings no evil to others, is a pleasant thing.", attribution: "Buddha" },
    { quote: "Radiate boundless love towards the entire world.", attribution: "Buddha" },
    { quote: "When watching after yourself, you watch after others. When watching after others, you watch after yourself.", attribution: "Buddha" },
    { quote: "One is not called noble who harms living beings. By not harming living beings, one is called noble.", attribution: "Buddha" },
    { quote: "He is able who thinks he is able.", attribution: "Buddha" },
    { quote: "Delight in heedfulness! Guard well your thoughts!", attribution: "Buddha" },
    { quote: "There is nothing so disobedient as an undisciplined mind, and nothing so obedient as a disciplined mind.", attribution: "Buddha" },
    { quote: "A mind unruffled by the vagaries of fortune, from sorrow freed, from defilements cleansed, from fear liberated — this is the greatest blessing.", attribution: "Buddha" },
    { quote: "You are a seeker. Delight in the mastery of your hands and your feet, of your words and your thoughts.", attribution: "Buddha" },
    { quote: "There is no path to happiness: happiness is the path.", attribution: "Buddha" },
    { quote: "Happiness comes when your work and words are of benefit to yourself and others.", attribution: "Buddha" },
    { quote: "Thousands of candles can be lighted from a single candle, and the life of the candle will not be shortened. Happiness never decreases by being shared.", attribution: "Buddha" },
    { quote: "It is in the nature of things that joy arises in a person free from remorse.", attribution: "Buddha" },
    { quote: "Do not dwell in the past, do not dream of the future, concentrate the mind on the present moment.", attribution: "Buddha" },
    { quote: "Should a person do good, let him do it again and again. Let him find pleasure therein, for blissful is the accumulation of good.", attribution: "Buddha" },
    { quote: "Set your heart on doing good. Do it over and over again, and you will be filled with joy.", attribution: "Buddha" },
    { quote: "The enlightened one, intent on meditation, should find delight in the forest, seeking satisfaction in solitude.", attribution: "Buddha" },
    { quote: "He who conquers himself is the mightiest warrior.", attribution: "Confucius" },
    { quote: "Be kind whenever possible. It is always possible.", attribution: "Dalai Lama" },
    { quote: "We are all one. Only egos, beliefs, and fears separate us.", attribution: "Anonymous" },
    { quote: "The only Zen you find at the top of the mountain is the Zen you bring with you.", attribution: "Zen Proverb" },
    { quote: "A beautiful thing is never perfect.", attribution: "Egyptian Proverb" },
    { quote: "If the only prayer you ever say in your entire life is 'Thank you', it will be enough.", attribution: "Meister Eckhart" },
    { quote: "Every answer you ever seek lies within your own silence.", attribution: "Anonymous" },
    { quote: "Act without expectation.", attribution: "Lao Tzu" },
    { quote: "The mind is everything. What you think, you become.", attribution: "Buddha" },
    { quote: "To the mind that is still, the universe surrenders.", attribution: "Lao Tzu" },
    { quote: "Better than a hundred years of mischief is one day spent in peace.", attribution: "Buddha" },
    { quote: "If light is in your heart, you will find your way home.", attribution: "Rumi" },
    { quote: "Die before you die, so that when you die, you won’t die.", attribution: "Muslim Proverb" },
    { quote: "Go forth on your path, as it exists only through your walking.", attribution: "Saint Augustine" },
    { quote: "Be like a tree and let the dead leaves drop.", attribution: "Rumi" },
    { quote: "I love you and that’s the beginning and end of everything.", attribution: "F. Scott Fitzgerald" }
  ];
  
  export const loveQuotes = [
    { quote: "I love you, and I will love you until I die, and if there’s a life after that, I’ll love you then.", attribution: "Cassandra Clare" },
    { quote: "Keep love in your heart. A life without it is like a sunless garden when the flowers are dead.", attribution: "Oscar Wilde" },
    { quote: "There is no remedy for love but to love more.", attribution: "Henry David Thoreau" },
    { quote: "For you see, each day I love you more, today more than yesterday and less than tomorrow.", attribution: "Rosemonde Gérard" },
    { quote: "I love you — I am at rest with you — I have come home.", attribution: "Dorothy L. Sayers" },
    { quote: "Never love anybody that treats you like you’re ordinary.", attribution: "Oscar Wilde" },
    { quote: "I love you, but you don’t know what you’re talking about.", attribution: "Moonrise Kingdom (Film)" },
    { quote: "Immature love says: ‘I love you because I need you.’ Mature love says: ‘I need you because I love you.’", attribution: "Erich Fromm" },
    { quote: "Everything is clearer when you’re in love.", attribution: "John Lennon" },
    { quote: "True love is like ghosts, which everyone talks about and few have seen.", attribution: "François de La Rochefoucauld" },
    { quote: "And I don’t know why but with you I’d dance in a storm in my best dress, fearless.", attribution: "Taylor Swift" },
    { quote: "True love stories never have endings.", attribution: "Richard Bach" },
    { quote: "When a woman is talking to you, listen to what she says with her eyes.", attribution: "Victor Hugo" },
    { quote: "Ultimately the bond of all companionship, whether in marriage or in friendship, is conversation.", attribution: "Oscar Wilde" },
    { quote: "In true love, the smallest distance is too great and the greatest distance can be bridged.", attribution: "Hans Nouwens" },
    { quote: "Long-term relationships, the ones that matter, are all about weathering the peaks and the valleys.", attribution: "Nicholas Sparks" },
    { quote: "If I had to choose between breathing and loving you I would use my last breath to tell you I love you.", attribution: "Deanna Anderson" },
    { quote: "True love comes quietly, without banners or flashing lights. If you hear bells, get your ears checked.", attribution: "Erich Segal" },
    { quote: "Ever has it been that love knows not its own depth until the hour of separation.", attribution: "Khalil Gibran" },
    { quote: "There is never a time or place for true love. It happens accidentally, in a heartbeat, in a single flashing, throbbing moment.", attribution: "Sarah Dessen" },
    { quote: "There is no charm equal to tenderness of heart.", attribution: "Jane Austen" },
    { quote: "I love you not only for what you are but for what I am when I am with you.", attribution: "Elizabeth Barrett Browning" },
    { quote: "True love lasts forever.", attribution: "Joseph B. Wirthlin" },
    { quote: "Who, being loved, is poor?", attribution: "Oscar Wilde" },
    { quote: "I think the universe meant for us to let go and just start something new with another person.", attribution: "Anonymous" },
    { quote: "Sometimes the heart sees what is invisible to the eye.", attribution: "H. Jackson Brown, Jr." },
    { quote: "If you find someone you love in your life, then hang on to that love.", attribution: "Princess Diana" },
    { quote: "Love is that condition in which the happiness of another person is essential to your own.", attribution: "Robert A. Heinlein" },
    { quote: "To love and be loved is to feel the sun from both sides.", attribution: "David Viscott" },
    { quote: "You know it’s true love when all you want is that person to be happy, even if you’re not part of their happiness.", attribution: "Julia Roberts" },
    { quote: "Love recognizes no barriers. It jumps hurdles, leaps fences, penetrates walls to arrive at its destination full of hope.", attribution: "Maya Angelou" },
    { quote: "Where there is love there is life.", attribution: "Mahatma Gandhi" },
    { quote: "The best thing to hold onto in life is each other.", attribution: "Audrey Hepburn" },
    { quote: "To love and win is the best thing. To love and lose is the next best.", attribution: "William Makepeace Thackeray" },
    { quote: "The greatest happiness of life is the conviction that we are loved — loved for ourselves, or rather, loved in spite of ourselves.", attribution: "Victor Hugo" },
    { quote: "Love does not consist in gazing at each other, but in looking outward together in the same direction.", attribution: "Antoine de Saint-Exupéry" },
    { quote: "Love is composed of a single soul inhabiting two bodies.", attribution: "Aristotle" },
    { quote: "The best proof of love is trust.", attribution: "Joyce Brothers" },
    { quote: "You know you’re in love when you can’t fall asleep because reality is finally better than your dreams.", attribution: "Dr. Seuss" },
    { quote: "True love doesn’t happen right away; it’s an ever-growing process. It develops after you’ve gone through many ups and downs, when you’ve suffered together, cried together, laughed together.", attribution: "Ricardo Montalban" },
    { quote: "Love is a friendship set to music.", attribution: "Joseph Campbell" },
    { quote: "Love is not about how much you say ‘I love you,’ but how much you can prove that it’s true.", attribution: "Unknown" },
    { quote: "Love isn’t something you find. Love is something that finds you.", attribution: "Loretta Young" },
    { quote: "To the world you may be one person, but to one person you are the world.", attribution: "Dr. Seuss" },
    { quote: "There is always some madness in love. But there is also always some reason in madness.", attribution: "Friedrich Nietzsche" },
    { quote: "It’s easy to fall in love. The hard part is finding someone to catch you.", attribution: "Bertrand Russell" },
    { quote: "Love is a canvas furnished by nature and embroidered by imagination.", attribution: "Voltaire" },
    { quote: "Love is the flower you’ve got to let grow.", attribution: "John Lennon" },
    { quote: "There is no cure for love but to love more.", attribution: "Henry David Thoreau" },
    { quote: "If I know what love is, it is because of you.", attribution: "Hermann Hesse" },
    { quote: "Love is an irresistible desire to be irresistibly desired.", attribution: "Robert Frost" },
    { quote: "Love is something sent from heaven to worry the hell out of you.", attribution: "Dolly Parton" },
    { quote: "Love is like war: easy to begin but very hard to stop.", attribution: "H. L. Mencken" },
    { quote: "Love makes your soul crawl out from its hiding place.", attribution: "Zora Neale Hurston" },
    { quote: "The best and most beautiful things in this world cannot be seen or even heard, but must be felt with the heart.", attribution: "Helen Keller" },
    { quote: "If you live to be a hundred, I want to live to be a hundred minus one day so I never have to live without you.", attribution: "A. A. Milne" },
    { quote: "A simple ‘I love you’ means more than money.", attribution: "Frank Sinatra" },
    { quote: "Love is a better teacher than duty.", attribution: "Albert Einstein" },
    { quote: "The heart wants what it wants. There’s no logic to these things.", attribution: "Woody Allen" },
    { quote: "Love is an act of endless forgiveness. Forgiveness is the key to action and freedom.", attribution: "Maya Angelou" },
    { quote: "You don’t love someone for their looks, or their clothes, or their fancy car, but because they sing a song only you can hear.", attribution: "Oscar Wilde" },
    { quote: "Love takes off masks that we fear we cannot live without and know we cannot live within.", attribution: "James Baldwin" },
    { quote: "We are shaped and fashioned by what we love.", attribution: "Johann Wolfgang von Goethe" },
    { quote: "When love is not madness it is not love.", attribution: "Pedro Calderón de la Barca" },
    { quote: "True love bears all, endures all and triumphs.", attribution: "Dada Vaswani" },
    { quote: "The best thing to hold onto in life is each other.", attribution: "Audrey Hepburn" },
    { quote: "The real lover is the man who can thrill you by kissing your forehead.", attribution: "Marilyn Monroe" },
    { quote: "We loved with a love that was more than love.", attribution: "Edgar Allan Poe" },
    { quote: "It is better to have loved and lost than never to have loved at all.", attribution: "Alfred Lord Tennyson" },
    { quote: "Love is like a fire. But whether it is going to warm your hearth or burn down your house, you can never tell.", attribution: "Joan Crawford" },
    { quote: "To love oneself is the beginning of a lifelong romance.", attribution: "Oscar Wilde" },
    { quote: "One word frees us of all the weight and pain of life: that word is love.", attribution: "Sophocles" },
    { quote: "If you remember me, then I don’t care if everyone else forgets.", attribution: "Haruki Murakami" },
    { quote: "Love is not something you feel, it is something you do.", attribution: "David Wilkerson" },
    { quote: "When we are in love we seem to ourselves quite different from what we were before.", attribution: "Blaise Pascal" },
    { quote: "Love is the greatest refreshment in life.", attribution: "Pablo Picasso" },
    { quote: "If you live to be a hundred, I want to live to be a hundred minus one day so I never have to live without you.", attribution: "A. A. Milne" },
    { quote: "I would rather spend one lifetime with you, than face all the ages of this world alone.", attribution: "J. R. R. Tolkien" },
    { quote: "The greatest thing you’ll ever learn is just to love and be loved in return.", attribution: "Eden Ahbez" },
    { quote: "Tis better to have loved and lost than never to have loved at all.", attribution: "Alfred Lord Tennyson" },
    { quote: "We accept the love we think we deserve.", attribution: "Stephen Chbosky" },
    { quote: "So, I love you because the entire universe conspired to help me find you.", attribution: "Paulo Coelho" },
    { quote: "If I know what love is, it is because of you.", attribution: "Hermann Hesse" },
    { quote: "The chance to love and be loved exists no matter where you are.", attribution: "Oprah Winfrey" },
    { quote: "Love is that condition in which the happiness of another person is essential to your own.", attribution: "Robert A. Heinlein" },
    { quote: "All you need is love. But a little chocolate now and then doesn’t hurt.", attribution: "Charles M. Schulz" },
    { quote: "It is not a lack of love, but a lack of friendship that makes unhappy marriages.", attribution: "Friedrich Nietzsche" },
    { quote: "Love is a friendship set to music.", attribution: "Joseph Campbell" },
    { quote: "We are most alive when we’re in love.", attribution: "John Updike" },
    { quote: "Nobody has ever measured, not even poets, how much the heart can hold.", attribution: "Zelda Fitzgerald" },
    { quote: "Love is a canvas furnished by nature and embroidered by imagination.", attribution: "Voltaire" },
    { quote: "If I had a flower for every time I thought of you... I could walk through my garden forever.", attribution: "Alfred Lord Tennyson" },
    { quote: "At the touch of love, everyone becomes a poet.", attribution: "Plato" },
    { quote: "You know you are in love when you see the world in her eyes, and her eyes everywhere in the world.", attribution: "David Levesque" },
    { quote: "Love isn’t blind; it just only sees what matters.", attribution: "William Curry" },
    { quote: "A kiss is a lovely trick designed by nature to stop speech when words become superfluous.", attribution: "Ingrid Bergman" },
    { quote: "Love is the expansion of two natures in such fashion that each include the other, each is enriched by the other.", attribution: "Felix Adler" },
    { quote: "Loving you was never an option. It was a necessity.", attribution: "Truth Devour" },
    { quote: "Love is when you meet someone who tells you something new about yourself.", attribution: "André Breton" },
    { quote: "There is always madness in love. But there is also always some reason in madness.", attribution: "Friedrich Nietzsche" },
    { quote: "If you want to know where your heart is, look where your mind goes when it wanders.", attribution: "Anonymous" },
    { quote: "To love is nothing. To be loved is something. But to love and be loved, that’s everything.", attribution: "T. Tolis" },
    { quote: "In the end, the love you take is equal to the love you make.", attribution: "Paul McCartney" },
    { quote: "Don’t limit your challenges. Challenge your limits.", attribution: "Anonymous" },
    { quote: "Let your love be a light that illuminates the darkest moments.", attribution: "Anonymous" },
    { quote: "Love is composed of a single soul inhabiting two bodies.", attribution: "Aristotle" }
  ];
  
  