import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './web.scss';
import axios from 'axios';
import { MarkThreeDocument, MarkThreeDocumentPatch, User } from './server';
import _ from 'lodash';
import { Ellipsis } from './icons';
import { Navigator } from './navigator';
import { hypeQuotes, loveQuotes, zenQuotes } from './quotes';
import * as serviceWorker from './serviceWorker';
import moment from 'moment';
import { toUnicodeVariant, shouldBold, toPlain} from './toUnicodeVariant';
import * as Diff from 'diff';

axios.defaults.validateStatus = () => true;

enum SyncStatus {
    SYNCED = 'synced',
    SYNC_PENDING = 'sync_pending',
    SYNC_FAILED = 'sync_failed'
}

const syncColors = {[SyncStatus.SYNCED]: '#00800061', [SyncStatus.SYNC_PENDING]: '#c3cd3573', [SyncStatus.SYNC_FAILED]: '#cd354373'};

interface MarkThreeState {
    isAuthenticated :boolean,
    document :MarkThreeDocument,
    lastDocument? :MarkThreeDocument,
    user :User,
    syncStatus :SyncStatus,
    showNavigator:boolean,
}

interface MarkThreeProps {

}

class SlashCommand {
    command :string
    replaceWith :string
}

class MarkThree extends React.Component<MarkThreeProps, MarkThreeState> {
    constructor(props) {
        super(props);
        this.onDocumentChange = this.onDocumentChange.bind(this);
        this.syncDoc = this.syncDoc.bind(this);
        this.debouncedSync = _.debounce(this.syncDoc, 1000, {trailing: true, leading: false});
        this.initializeDoc = this.initializeDoc.bind(this);
        this.checkDocSync = this.checkDocSync.bind(this);
        this.clientID = Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2);

        this.state = {
            isAuthenticated: false,
            document: {} as MarkThreeDocument,
            lastDocument: null,
            user: {} as User,
            syncStatus: SyncStatus.SYNCED,
            showNavigator: false,
        }
    }

    clientID :string
    lastDocumentLength :number = 0;

    async checkDocSync() {
        // long polling, will wait for 20 seconds
        let hasChanged = await axios.get(`/document/${this.state.user.currentDocument}/check?version=${this.state.document.version}&clientID=${this.clientID}`);

        if(hasChanged.status === 409) {
            await this.initializeDoc();
        }

        this.checkDocSync()
    }

    async initializeDoc() {
        if(this.state.syncStatus === SyncStatus.SYNC_FAILED) {
            let confirmed = confirm('Connection re-established. Push new changes?');

            if(confirmed) {
                await axios.put(`/document/${this.state.user.currentDocument}?clientID=${this.clientID}&force=true`, this.state.document);
                this.setState({syncStatus: SyncStatus.SYNCED});
                return;
            }
        }

        let markThreeDocument = (await axios.get(`/document/${this.state.user.currentDocument}`)).data as MarkThreeDocument;
    
        this.lastDocumentLength = markThreeDocument.documentText.split('\n').length;
        await new Promise(resolve => this.setState({ document: markThreeDocument, syncStatus: SyncStatus.SYNCED, lastDocument: null }, () => {
            setTimeout(() => {
                // set cursor to the bottom of the doc
                const el = document.querySelector('textarea');
                el.focus();

                let cursorPosition = (sessionStorage.getItem('cursorPosition') && parseInt(sessionStorage.getItem('cursorPosition'))) || el.value.length;
                el.setSelectionRange(cursorPosition, cursorPosition);
                el.scrollTop = el.scrollHeight;
            }, 100);
            resolve(true);
        }));
    }

    debouncedSync :() => void;

    async syncDoc() {
        let markThreeDocument = _.cloneDeep(this.state.document) as MarkThreeDocument;
        markThreeDocument.version = markThreeDocument.version + 1;
        this.setState({ document: markThreeDocument });

        // if the lastMarkThreeDocument is not empty, compare the lastMarkThreeDocument with the current document.
        let res;
        if(this.state.lastDocument && this.state.lastDocument.documentText) {
            let patch = Diff.createPatch('document', this.state.lastDocument.documentText, markThreeDocument.documentText, '', '');
            let markThreeDocumentPatch = {documentID: this.state.user.currentDocument, version: markThreeDocument.version, patch} as MarkThreeDocumentPatch;
            try {
                res = await axios.patch(`/document/${this.state.user.currentDocument}?clientID=${this.clientID}`, markThreeDocumentPatch);
            } catch (e) {
                this.setState({ syncStatus: SyncStatus.SYNC_FAILED});
                return;
            }

        } else {
            // if it is empty, just send the entire document
            try {
                res = await axios.put(`/document/${this.state.user.currentDocument}?clientID=${this.clientID}`, markThreeDocument);
            } catch {
                this.setState({ syncStatus: SyncStatus.SYNC_FAILED});
                return;
            }
        }

        this.setState({lastDocument: markThreeDocument});


        if(res.status === 409) {
            this.initializeDoc();
            return;
        }

        if(res.status === 200) {
            this.setState({syncStatus: SyncStatus.SYNCED})
            return;
        }

        if(res.status === 403) {
            window.location.reload();
        }

        this.setState({ syncStatus: SyncStatus.SYNC_FAILED});
    }

    async componentDidMount() {
        let res = await axios.get('/is-authenticated', {validateStatus: () => true});

        if(res.status !== 200) {
            window.location.href = "/login";
        } else {
            let user = res.data as User;
            if(!user.currentDocument) {
                user.currentDocument = (await axios.post('/document')).data.documentID;
            }

            this.setState({user, isAuthenticated: true}, async () => {
                await this.initializeDoc()
                this.checkDocSync();
            });
        }
    }

    async onDocumentChange(e :React.ChangeEvent<HTMLTextAreaElement>) {
        e.preventDefault();

        setTimeout(() => {
            let cursorPosition = e.target.selectionStart;
            sessionStorage.setItem('cursorPosition', cursorPosition.toString());
        }, 100);


        let m3document = {...this.state.document};

        let allText = e.target.value;

        let initialCaretPosition = document.querySelector("textarea").selectionStart; 

        // insert a $#$ at the cursor position, this will be removed later
        allText = allText.substring(0, initialCaretPosition) + '$#$' + allText.substring(initialCaretPosition);

        let startText = Math.max(initialCaretPosition - 100, 0);
        let endText = Math.min(initialCaretPosition + 100, allText.length);

        let text = allText.substring(startText, endText);


                // Helper function to get the next occurrence of a target ISO weekday (1 = Monday, …, 7 = Sunday)
        const getNextWeekday = (targetDay: number) => {
            const now = moment();
            const currentDay = now.isoWeekday();
            let diff = targetDay - currentDay;
            if (diff <= 0) {
                diff += 7;
            }
            return moment().add(diff, 'days').format('LL');
        };

        const slashCommands = [
            {command: '/today', replaceWith: moment().format('LL')},
            {command: '/tomorrow', replaceWith: moment().add(1, 'day').format('LL')},
            {command: '/nextweek', replaceWith: `Week of ${moment().add(1, 'weeks').isoWeekday(1).format('LL')}`},
            {command: '/thisweek', replaceWith: `Week of ${moment().isoWeekday(1).format('LL')}`},
            {command: '/mon', replaceWith: getNextWeekday(1)},
            {command: '/tue', replaceWith: getNextWeekday(2)},
            {command: '/wed', replaceWith: getNextWeekday(3)},
            {command: '/thu', replaceWith: getNextWeekday(4)},
            {command: '/fri', replaceWith: getNextWeekday(5)},
            {command: '/sat', replaceWith: getNextWeekday(6)},
            {command: '/sun', replaceWith: getNextWeekday(7)},
            {command: '\n- ', replaceWith: '\n  • '},
            {command: '\n  /todo', replaceWith: '\n  ☐ '},
            {command: '\n /todo', replaceWith: '\n  ☐ '},
            {command: '\n/todo', replaceWith: '\n  ☐ '},
            {command: '\n  /t', replaceWith: '\n  ☐ '},
            {command: '\n /t', replaceWith: '\n  ☐ '},
            {command: '\n/t', replaceWith: '\n  ☐ '},
            {command: '\n  /done', replaceWith: '\n  ☑ '},
            {command: '\n /done', replaceWith: '\n  ☑ '},
            {command: '\n/done', replaceWith: '\n  ☑ '},
            {command: '\n  /d', replaceWith: '\n  ☑ '},
            {command: '\n /d', replaceWith: '\n  ☑ '},
            {command: '\n/d', replaceWith: '\n  ☑ '},
            {command: '\n #', replaceWith: '\n#'},
            {command: '\n  #', replaceWith: '\n#'},
            {command: '\n   #', replaceWith: '\n#'},
            {command: '\n☐', replaceWith: '\n  ☐'},
            {command: '\n ☐', replaceWith: '\n  ☐'},
            {command: '\n   ☐', replaceWith: '\n  ☐'},
            {command: '\n    ☐', replaceWith: '\n  ☐'},
            {command: '\n     ☐', replaceWith: '\n  ☐'},
            {command: '\n      ☐', replaceWith: '\n  ☐'},
            {command: '\n       ☐', replaceWith: '\n  ☐'},
            {command: '\n☑', replaceWith: '\n  ☑'},
            {command: '\n ☑', replaceWith: '\n  ☑'},
            {command: '\n   ☑', replaceWith: '\n  ☑'},
            {command: '\n    ☑', replaceWith: '\n  ☑'},
            {command: '\n     ☑', replaceWith: '\n  ☑'},
            {command: '\n      ☑', replaceWith: '\n  ☑'},
            {command: '--', replaceWith: '—'},
            {command: '-->', replaceWith: '➞'},
            {command: '—>', replaceWith: '➞'},
            {command: '=>', replaceWith: '⇒'},
        ] as SlashCommand[]

        let usedCommand = null as SlashCommand;

        slashCommands.forEach(cmd => {

            // make cmd.command a case insensitive regex
            let re = new RegExp(cmd.command, 'i');

            // if the command is found in the text, replace it with replaceWith
            if (re.test(text)) {
                text = text.replace(re, cmd.replaceWith);
                usedCommand = cmd;
            }

            let boldRe = new RegExp(toUnicodeVariant(cmd.command.substring(0, cmd.command.length - 1), 'bs') + cmd.command[cmd.command.length - 1], 'i');
            
            if(boldRe.test(text)) {
                usedCommand = {} as SlashCommand;
                usedCommand.command = toUnicodeVariant(cmd.command, 'bs')
                usedCommand.replaceWith = toUnicodeVariant(cmd.replaceWith, 'bs');
                text = text.replace(boldRe, usedCommand.replaceWith);
            }
        });

        // Get the cursorAtIndex variable by calculating it from the startText length.
        let splitLines = text.split('\n');
        let cursorAtIndex = splitLines.findIndex(line => line.includes('$#$'));

        const numLines = allText.split('\n').length;
        const pressedEnter = numLines > this.lastDocumentLength;
        this.lastDocumentLength = numLines;
        splitLines = splitLines.map(line => line.replace('$#$', ''));
        text = splitLines.map((line, index) => {
            if(line.startsWith('#') && line.length > 3) {
                usedCommand = {} as SlashCommand;
                usedCommand.command = line;
                usedCommand.replaceWith = toUnicodeVariant(line.replace(/#/g, '').trim(), 'bs'); 
                line = line.replace(usedCommand.command, usedCommand.replaceWith)
            }

            // if the first character in the line is a bold unicode character, make the entire line bold (header mode)
            if(line.length > 0 && shouldBold(line)) {
                usedCommand = {} as SlashCommand;
                usedCommand.command = line;
                usedCommand.replaceWith = toUnicodeVariant(line, 'bs');
                line = line.replace(usedCommand.command, usedCommand.replaceWith)
            }

            // Convert strings like *bold* to unicode bold
            const boldRegex = / \*([^*]+)\* /g;
            if(boldRegex.test(line)) {
                usedCommand = {} as SlashCommand;
                usedCommand.command = line.match(boldRegex)[0]
                usedCommand.replaceWith = toUnicodeVariant(usedCommand.command.replace(/\*/g, ''), 'bs');
                line = line.replace(usedCommand.command, usedCommand.replaceWith)
            }

            // Convert strings like _italic_ to unicode italic
            const italicRegex = /\s_([^_]+)_\s/g;
            if(italicRegex.test(line)) {
                usedCommand = {} as SlashCommand;
                usedCommand.command = line.match(italicRegex)[0]
                usedCommand.replaceWith = toUnicodeVariant(usedCommand.command.replace(/_/g, ''), 'is');
                line = line.replace(usedCommand.command, usedCommand.replaceWith)
            }

            // endsWithDone regex tests line to see if it ends with /done, may contain spaces after /done, case insensitive
            let endsWithDone = /\/done$/i;
            let endsWithSlashD = /\/d$/i;
            if(line.startsWith('  ☐') && (endsWithDone.test(line) || endsWithSlashD.test(line))) {
                usedCommand = {} as SlashCommand;
                usedCommand.command = line;

                let task = line.replace('  ☐', '').replace(endsWithDone, '').replace(endsWithSlashD, '').trim();
                task = toUnicodeVariant(task, '', 'strike');
                task = `  ☑ ${task}`;
                usedCommand.replaceWith = task; 
                line = line.replace(usedCommand.command, usedCommand.replaceWith)
            }

            // ending a line with /nah will make it a strike through and replace the ballot box with a X ballot box
            let nahRegex = /\/nah$/i;
            if(line.startsWith('  ☐') && nahRegex.test(line)) {
                usedCommand = {} as SlashCommand;
                usedCommand.command = line;

                let task = line.replace('  ☐', '').replace(nahRegex, '').trim();
                task = toUnicodeVariant(task, '', 'strike');
                task = `  ☒ ${task}`
                usedCommand.replaceWith = task;
                line = line.replace(usedCommand.command, usedCommand.replaceWith)
            }

            const undoRegex = /\/undo$/i;
            if(undoRegex.test(line)) {
                usedCommand = {} as SlashCommand;
                usedCommand.command = line;
                usedCommand.replaceWith = toPlain(line, '')
                    .replace("  ☑", "  ☐")
                    .replace("  ☒", "  ☐")
                    .replace(undoRegex, '')
                    .trimEnd();
                line = line.replace(usedCommand.command, usedCommand.replaceWith)
            }

            // /plain and /𝗽𝗹𝗮𝗶𝗻
            const plainRegex = /\/plain$/i;
            if(plainRegex.test(toPlain(line, 'bs'))) {
                usedCommand = {} as SlashCommand;
                usedCommand.command = line;
                usedCommand.replaceWith = toPlain(toPlain(line, 'is'), 'bs')
                    .replace(plainRegex, '')
                    .trimEnd();
                line = line.replace(usedCommand.command, usedCommand.replaceWith)
            }

            // Line start with /hr, replace it with a horizontal rule
            const hrRegex = /\/hr$/i;
            if(hrRegex.test(line)) {
                usedCommand = {} as SlashCommand;
                usedCommand.command = line;
                usedCommand.replaceWith = 'ᚏᚏᚏᚏᚏᚏᚏᚏᚏᚏᚏᚏᚏᚏᚏᚏᚏᚏᚏᚏᚏᚏᚏ\n';
                line = line.replace(usedCommand.command, usedCommand.replaceWith)
            }

            // If the line start with /hype, replace it with a random quote from the hypeQuotes array
            const hypeRegex = /\/hype$/i;
            if(hypeRegex.test(line)) {
                usedCommand = {} as SlashCommand;
                usedCommand.command = line;
                const quote = hypeQuotes[Math.floor(Math.random() * hypeQuotes.length)];

                let formattedQuote = `"${quote.quote}"\n    ~ ${quote.attribution}`;
                usedCommand.replaceWith = formattedQuote;
                line = line.replace(usedCommand.command, usedCommand.replaceWith)
            }

            // If the line start with /love, replace it with a random quote from the loveQuotes array
            const loveRegex = /\/love$/i;
            if(loveRegex.test(line)) {
                usedCommand = {} as SlashCommand;
                usedCommand.command = line;
                const quote = loveQuotes[Math.floor(Math.random() * loveQuotes.length)];
                let formattedQuote = `"${quote.quote}"\n    ~ ${quote.attribution}`;
                usedCommand.replaceWith = formattedQuote;
                line = line.replace(usedCommand.command, usedCommand.replaceWith)
            }

            // If the line start with /zen, replace it with a random quote from the zenQuotes array
            const zenRegex = /\/zen$/i;
            if(zenRegex.test(line)) {
                usedCommand = {} as SlashCommand;
                usedCommand.command = line;
                const quote = zenQuotes[Math.floor(Math.random() * zenQuotes.length)];
                let formattedQuote = `"${quote.quote}"\n    ~ ${quote.attribution}`;
                usedCommand.replaceWith = formattedQuote;
                line = line.replace(usedCommand.command, usedCommand.replaceWith)
            }


            if(index > 0 && pressedEnter && cursorAtIndex === index) {
                // If the previous line is a bullet point and the current line is empty, make it a new bullet point
                let previousLine = splitLines[index - 1];
                let previousLineIsBulletPointWithContent = /^\s*•\s*[^\s]/.test(previousLine);

                if(previousLineIsBulletPointWithContent && line === '') {
                    usedCommand = {} as SlashCommand;
                    usedCommand.command = line;
                    usedCommand.replaceWith = '  • ';
                    line = line.replace(usedCommand.command, usedCommand.replaceWith)
                }
            }

            if(index > 0 && pressedEnter && cursorAtIndex === index) {
                // If the previous line is a checkbox and the current line is empty, make it a new checkbox
                let previousLine = splitLines[index - 1];
                let previousLineIsBulletPointWithContent = /^\s*[☐☑☒]\s*[^\s]/.test(previousLine);

                if(previousLineIsBulletPointWithContent && line === '') {
                    usedCommand = {} as SlashCommand;
                    usedCommand.command = line;
                    usedCommand.replaceWith = '  ☐ ';
                    line = line.replace(usedCommand.command, usedCommand.replaceWith)
                }
            }

            if(index > 0 && cursorAtIndex === index + 1 && index < splitLines.length - 2) {
                // If the cursor is on the next line and the next line is empty,
                // and the current line is an empty bullet point, trim the current line to be empty.
                let nextLine = splitLines[index + 1];
                const previousLineIsAnEmptyListEntry = /^\s*[☐☑☒•]\s*$/.test(line);
                if(nextLine === '' && previousLineIsAnEmptyListEntry) {
                    usedCommand = {} as SlashCommand;
                    usedCommand.command = line;
                    usedCommand.replaceWith = '';
                    line = line.replace(usedCommand.command, usedCommand.replaceWith)
                }
            }

            return line;
        }).join('\n');

        m3document.documentText = [allText.substring(0, startText), text, allText.substring(endText)].join('');
        let syncStatus = this.state.syncStatus === SyncStatus.SYNC_FAILED ? SyncStatus.SYNC_FAILED : SyncStatus.SYNC_PENDING;
        this.setState({ document: m3document, syncStatus }, () => {
            if(usedCommand) {
                let caretPos = initialCaretPosition - usedCommand!.command.length + usedCommand!.replaceWith.length;
                document.querySelector("textarea").setSelectionRange(caretPos, caretPos)
            }
            this.debouncedSync();
        });
    }

    render() {
        return <div className="container" style={{borderLeft: `5px solid ${this.state.showNavigator ? 'transparent' : syncColors[this.state.syncStatus]}`}}><div className="markthree">
                {!this.state.isAuthenticated && <>
                <h1>MarkThree</h1>
                <h3><em>loading...</em></h3>
                </>}

                {this.state.isAuthenticated && <div hidden={this.state.showNavigator} className="document">
                    <textarea
                    spellCheck={false} 
                    value={this.state.document.documentText} 
                    onChange={this.onDocumentChange}></textarea>
                    <div className="to-navigator"><a onClick={() => this.setState({ showNavigator: true })}><Ellipsis /></a></div>
                </div>}

                {this.state.isAuthenticated && this.state.showNavigator && <Navigator 
                closeModal={() => this.setState({ showNavigator: false})}
                setCurrentDocument={(currentDocument :string) => {
                    let user = {...this.state.user};
                    user.currentDocument = currentDocument;
                    this.setState({user, showNavigator: false}, this.initializeDoc);
                }} />}
            </div></div>
    }
}

const root = ReactDOMClient.createRoot(document.getElementById('root')!);
root.render(<MarkThree />);

serviceWorker.register();
